import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertTriangle } from 'featherico';
import { useEffect, useState } from 'react';
import * as React from 'react';
import Crossfade from '../../components/Crossfade';
import styles from './style.m.less';
import { useHeightAsCssVariable } from '../../hooks/useHeightAsCssVariable';
import { isFeatureEnabled } from '../../services/Features';
export const OFFLINE_WARNING_ENTRYPOINT_ID = 'offline-warning';
const OfflineWarning = ({ mountNode }) => {
    const [isOnline, setIsOnline] = useState(window.navigator && typeof window.navigator.onLine === 'boolean' ? window.navigator.onLine : true);
    useEffect(() => {
        mountNode.classList.add(styles.container);
        return () => mountNode.classList.remove(styles.container);
    }, [mountNode]);
    useEffect(() => {
        const offlineHandler = () => setIsOnline(false);
        const onlineHandler = () => setIsOnline(true);
        window.addEventListener('offline', offlineHandler);
        window.addEventListener('online', onlineHandler);
        return () => {
            window.removeEventListener('offline', offlineHandler);
            window.removeEventListener('online', onlineHandler);
        };
    }, []);
    useHeightAsCssVariable('--offlineWarningOffset', mountNode);
    const alwaysOnline = isFeatureEnabled('FEATURE_alwaysOnline');
    return (_jsx(Crossfade, { children: isOnline || alwaysOnline ? (_jsx("noscript", {}, "online")) : (_jsx("div", { className: styles.warning, children: _jsxs("span", { children: [_jsxs("strong", { children: [_jsx(IconAlertTriangle, {}), " You seem to be offline."] }), ' ', "Changes made now will not be saved."] }) }, "offline")) }));
};
export default OfflineWarning;
