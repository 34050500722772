import { castArray, isEmpty } from 'lodash/fp';
import api from '../../../api';
import { concatAnd } from '../../services/Enumeration';
import { isFeatureEnabled } from '../../services/Features';
import { get as getPluralized } from '../../services/Pluralize';
export async function invalidateEmailTemplateApi() {
    await Promise.all([
        api.emailTemplate.invalidate(),
        api.emailTemplateMini.invalidate(),
        api.emailTemplatePreview.invalidate(),
    ]);
}
export function getDisabledReasonForDto(dtos) {
    const disabledTemplates = castArray(dtos).filter(dto => !dto.enabled);
    return getDisabledReasonString(disabledTemplates);
}
export function getDisabledReason(possibleEmails, emailTemplates) {
    const disabledTemplates = castArray(possibleEmails).reduce((acc, type) => {
        const template = emailTemplates[type];
        if (template) {
            if (!template.enabled) {
                acc.push(template);
            }
        }
        else {
            // eslint-disable-next-line no-console
            console.error(`Template ${type} not provided.`);
        }
        return acc;
    }, []);
    if (possibleEmails.length === disabledTemplates.length) {
        return getDisabledReasonString(disabledTemplates);
    }
    else {
        return;
    }
}
function getDisabledReasonString(disabledTemplates) {
    if (isEmpty(disabledTemplates)) {
        return;
    }
    const disabledCount = disabledTemplates.length;
    const disabledNames = disabledTemplates.map(template => `'${template.templateType.name}'`);
    return `The ${getPluralized('email', disabledCount)} ${concatAnd(disabledNames)} ${getPluralized('is', disabledCount)} disabled`;
}
export function getCanEditTemplate(templateTypeDto) {
    if (!isFeatureEnabled('FEATURE_customization') &&
        templateTypeDto.moduleType !== 'APP' &&
        templateTypeDto.moduleType !== 'USER') {
        return { canEdit: false, reason: 'This email cannot be edited on your current pricing plan' };
    }
    switch (templateTypeDto.editPermission) {
        case 'ALLOWED':
            return { canEdit: true };
        case 'FORBIDDEN':
            return { canEdit: false, reason: 'This email cannot be edited' };
        case 'RESTRICTED':
            return isFeatureEnabled('FEATURE_allowEditingRestrictedEmailTemplates')
                ? { canEdit: true }
                : { canEdit: false, reason: 'This email cannot be edited during trial' };
    }
}
